"use client";

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { api } from "@/api/api";
import type {
  StructureType,
  IncidentType,
  DamageType,
  IncidentPreset,
  IncidentPresetShortcut,
  UserStateSubscription,
  UserPresetSubscription,
  Industry,
  User,
  IncidentFilter,
} from "@/types";

interface ConfigResponse {
  success: boolean;
  structureTypes: StructureType[];
  incidentTypes: IncidentType[];
  damageTypes: DamageType[];
  incidentPresets: IncidentPreset[];
  incidentPresetShortcuts: IncidentPresetShortcut[];
  industries: Industry[];
  userPlaceholderImage: string;
}

interface UserSubscriptionsResponse {
  success: boolean;
  presetSubscriptions: UserPresetSubscription[];
  stateSubscriptions: UserStateSubscription[];
  cityExclusions: string[];
  countyExclusions: string[];
}

interface CallTypesContextValue {
  structureTypes: StructureType[];
  incidentTypes: IncidentType[];
  damageTypes: DamageType[];
  incidentPresets: IncidentPreset[];
  presetShortcuts: IncidentPresetShortcut[];
  userSubscriptions: {
    presetSubscriptions: UserPresetSubscription[];
    stateSubscriptions: UserStateSubscription[];
    cityExclusions: string[];
    countyExclusions: string[];
    activeSubscriptions: Set<string>;
  };
  togglePresetSubscription: {
    mutate: (presetLabel: string) => void;
    isPending: boolean;
  };
  toggleBusinessPresetSubscription: {
    mutate: (data: { businessId: string; label: string }) => void;
    isPending: boolean;
  };
  bulkUpdatePresetSubscriptions: {
    mutate: () => void;
    isPending: boolean;
  };
  deleteAllPresetSubscriptions: {
    mutate: () => void;
    isPending: boolean;
  };
  updateIncidentFilter: {
    mutate: (filter: Partial<IncidentFilter>) => void;
    isPending: boolean;
  };
  currentFilter: IncidentFilter | undefined;
  isLoading: boolean;
  error: Error | null;
}

interface CallTypesProviderProps {
  children: ReactNode;
  self?: User | null;
  accessToken?: string;
}

const defaultCallTypesContext: CallTypesContextValue = {
  structureTypes: [],
  incidentTypes: [],
  damageTypes: [],
  incidentPresets: [],
  presetShortcuts: [],
  userSubscriptions: {
    presetSubscriptions: [],
    stateSubscriptions: [],
    cityExclusions: [],
    countyExclusions: [],
    activeSubscriptions: new Set<string>(),
  },
  togglePresetSubscription: {
    mutate: () =>
      console.warn("togglePresetSubscription called outside of provider"),
    isPending: false,
  },
  toggleBusinessPresetSubscription: {
    mutate: () =>
      console.warn(
        "toggleBusinessPresetSubscription called outside of provider",
      ),
    isPending: false,
  },
  bulkUpdatePresetSubscriptions: {
    mutate: () =>
      console.warn("bulkUpdatePresetSubscriptions called outside of provider"),
    isPending: false,
  },
  deleteAllPresetSubscriptions: {
    mutate: () =>
      console.warn("deleteAllPresetSubscriptions called outside of provider"),
    isPending: false,
  },
  updateIncidentFilter: {
    mutate: () =>
      console.warn("updateIncidentFilter called outside of provider"),
    isPending: false,
  },
  currentFilter: undefined,
  isLoading: false,
  error: null,
};

export const CallTypesContext = createContext<CallTypesContextValue>(
  defaultCallTypesContext,
);

export function CallTypesProvider({
  children,
  self,
  accessToken,
}: CallTypesProviderProps) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!accessToken) {
      queryClient.clear();
    }
  }, [accessToken, queryClient]);

  const queryKeys = {
    config: ["config"] as const,
    userSubscriptions: (userId: string) =>
      ["user-subscriptions", userId] as const,
    businessSubscriptions: (businessId: string) =>
      ["business-subscriptions", businessId] as const,
    incidents: ["incidents"] as const,
  };

  const {
    data: config,
    isLoading: isConfigLoading,
    error: configError,
  } = useQuery({
    queryKey: queryKeys.config,
    queryFn: async () => {
      const response = await api.get<ConfigResponse>("config");
      return response.data;
    },
    enabled: !!accessToken && !!self?.id,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const {
    data: userSubscriptions,
    isLoading: isSubsLoading,
    error: subsError,
  } = useQuery({
    queryKey: queryKeys.userSubscriptions(self?.id ?? ""),
    queryFn: async () => {
      if (!self?.id || !accessToken) return null;

      const [
        presetsResponse,
        statesResponse,
        cityExclusionsResponse,
        countyExclusionsResponse,
      ] = await Promise.all([
        api.get<{ success: boolean; subscriptions: UserPresetSubscription[] }>(
          `users/${self.id}/subscriptions/presets`,
        ),
        api.get<{ success: boolean; subscriptions: UserStateSubscription[] }>(
          `users/${self.id}/subscriptions/states`,
        ),
        api.get<{ success: boolean; exclusions: string[] }>(
          `users/${self.id}/exclusions/cities`,
        ),
        api.get<{ success: boolean; exclusions: string[] }>(
          `users/${self.id}/exclusions/counties`,
        ),
      ]);

      return {
        success: true,
        presetSubscriptions: presetsResponse.data.subscriptions,
        stateSubscriptions: statesResponse.data.subscriptions,
        cityExclusions: cityExclusionsResponse.data.exclusions,
        countyExclusions: countyExclusionsResponse.data.exclusions,
      };
    },
    enabled: !!self?.id && !!accessToken,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const { data: currentFilter } = useQuery<IncidentFilter>({
    queryKey: ["filters", "incidents"],
    enabled: !!accessToken,
  });

  const togglePresetMutation = useMutation({
    mutationFn: async (label: string) => {
      if (!self?.id || !accessToken)
        throw new Error("No user ID or access token available");
      return api.put(`users/${self.id}/subscriptions/presets`, null, {
        params: { label },
      });
    },
    onSuccess: () => {
      if (self?.id) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(self.id),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const toggleBusinessPresetMutation = useMutation({
    mutationFn: async (data: { businessId: string; label: string }) => {
      return api.put(
        `businesses/${data.businessId}/subscriptions/presets`,
        undefined,
        {
          params: {
            label: data.label,
          },
        },
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.businessSubscriptions(variables.businessId),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.incidents,
      });
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const bulkUpdateMutation = useMutation({
    mutationFn: async () => {
      if (!self?.id || !accessToken)
        throw new Error("No user ID or access token available");
      return api.post(`users/${self.id}/subscriptions/presets/all`);
    },
    onSuccess: () => {
      if (self?.id) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(self.id),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const deleteAllPresetsMutation = useMutation({
    mutationFn: async () => {
      if (!self?.id || !accessToken)
        throw new Error("No user ID or access token available");
      return api.post(`users/${self.id}/subscriptions/presets/delete`);
    },
    onSuccess: () => {
      if (self?.id) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(self.id),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const updateFilterMutation = useMutation({
    mutationFn: (filter: Partial<IncidentFilter>) => {
      queryClient.setQueryData<IncidentFilter>(
        ["filters", "incidents"],
        (storedFilter) => {
          if (!storedFilter) {
            return undefined;
          }
          return {
            ...storedFilter,
            ...filter,
          };
        },
      );
      return Promise.resolve();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["businesses"] });
      queryClient.invalidateQueries({ queryKey: ["config"] });
    },
  });

  const activeSubscriptions = useMemo(() => {
    if (!userSubscriptions?.presetSubscriptions) return new Set<string>();
    return new Set(
      userSubscriptions.presetSubscriptions.map(
        (sub) => `${sub.StructureTypeId}-${sub.IncidentTypeId}`,
      ),
    );
  }, [userSubscriptions?.presetSubscriptions]);

  useEffect(() => {
    return () => {
      if (self?.id) {
        queryClient.removeQueries({
          queryKey: queryKeys.userSubscriptions(self.id),
        });
        queryClient.removeQueries({
          queryKey: queryKeys.config,
        });
      }
    };
  }, [queryClient, self?.id]);

  const value: CallTypesContextValue = {
    structureTypes: config?.structureTypes || [],
    incidentTypes: config?.incidentTypes || [],
    damageTypes: config?.damageTypes || [],
    incidentPresets: config?.incidentPresets || [],
    presetShortcuts: config?.incidentPresetShortcuts || [],
    userSubscriptions: {
      presetSubscriptions: userSubscriptions?.presetSubscriptions || [],
      stateSubscriptions: userSubscriptions?.stateSubscriptions || [],
      cityExclusions: userSubscriptions?.cityExclusions || [],
      countyExclusions: userSubscriptions?.countyExclusions || [],
      activeSubscriptions,
    },
    togglePresetSubscription: {
      mutate: togglePresetMutation.mutate,
      isPending: togglePresetMutation.isPending,
    },
    toggleBusinessPresetSubscription: {
      mutate: toggleBusinessPresetMutation.mutate,
      isPending: toggleBusinessPresetMutation.isPending,
    },
    bulkUpdatePresetSubscriptions: {
      mutate: bulkUpdateMutation.mutate,
      isPending: bulkUpdateMutation.isPending,
    },
    deleteAllPresetSubscriptions: {
      mutate: deleteAllPresetsMutation.mutate,
      isPending: deleteAllPresetsMutation.isPending,
    },
    updateIncidentFilter: {
      mutate: updateFilterMutation.mutate,
      isPending: updateFilterMutation.isPending,
    },
    currentFilter,
    isLoading: isConfigLoading || isSubsLoading,
    error: configError || subsError || error,
  };

  return (
    <CallTypesContext.Provider value={value}>
      {children}
    </CallTypesContext.Provider>
  );
}

export const useCallTypes = () => {
  const context = useContext(CallTypesContext);
  if (!context) {
    throw new Error("useCallTypes must be used within CallTypesProvider");
  }
  return context;
};
